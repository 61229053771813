import React from 'react';
import './Loading-spinner.css'
const LoadingSpinner = () => (
  <div className="loading-spinner-container">
    <div className="loading-spinner">
      <div className="spinner-circle"></div>
    </div>
  </div>
);

export default LoadingSpinner;