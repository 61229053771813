import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="not-found-wrapper">
      <Breadcrumbs title="Sayfa Bulunamadı" pagename="404 Error" />
      <Container fluid className="not-found-container">
        <div className="not-found-content">
          <h1 className="not-found-title">
            4<span className="not-found-o">0</span>4
          </h1>
          <h2 className="not-found-subtitle">Sayfa Bulunamadı</h2>
          <p className="not-found-message">
            Aradığınız sayfa mevcut değil veya taşınmış olabilir.
          </p>
          <Button as={Link} to="/" variant="outline-primary" className="not-found-button">
            Ana Sayfaya Dön
          </Button>
        </div>
        <div className="not-found-background"></div>
      </Container>
    </div>
  );
};

export default NotFound;