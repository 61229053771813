import Home from './pages/Home/Home'
import './App.css';
import ScrollToTop from './components/Common/ScrolToTop/ScrolToTop';
import { Route, Routes } from 'react-router-dom';
import Header from  './components/Common/Header/Header';
import Footer from  './components/Common/Footer/Footer';
import RoomType from './pages/RoomType/RoomType';
import PhotoGallery from './pages/PhotoGallery/PhotoGallery';
import Contact from './pages/Contact/Contact';
import About from './pages/About/About';
import RoomTypeDetail from './pages/RoomType/RoomTypeDetail';
import OnlineRes from './pages/OnlineReservation/OnlineRes';
import NotFound from './components/NotFound/NotFound';

function App() {
  return (
   <>
   <div className="main-content">
   <Header />
   <ScrollToTop />  
   <Routes>
   <Route path='/' element={<Home />} />
    <Route path='about-us' element={<About />} />
    <Route path='contact-us' element={<Contact />} />
    <Route path='photogallery' element={<PhotoGallery/>} />
    <Route path='roomtype' element={<RoomType />} />
    <Route path='/roomtype-detail/:title' element={<RoomTypeDetail />} />
    <Route path='OnlineRes' element={<OnlineRes />} />
    <Route path='*' element={<NotFound />} /> {/* 404 sayfası için catch-all route */}
   </Routes>
   </div>
   <Footer />
   </>
  );
}

export default App;
