import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../Gallery/gallery.css";
import HotelPhoto1 from "../../assets/images/1207/01bb9356_z.jpg";
import HotelPhoto2 from "../../assets/images/1207/da02b9a9_z.jpg";
import HotelPhoto3 from "../../assets/images/1207/a6dd0056_z.jpg";
import HotelPhoto4 from "../../assets/images/1207/1cd0e3bf_z.jpg";
import HotelPhoto5 from "../../assets/images/1207/2fb67bb9_z.jpg";
import HotelPhoto6 from "../../assets/images/1207/3d0d85be_z.jpg";
import HotelPhoto7 from "../../assets/images/1207/9217c869_z.jpg";
import HotelPhoto8 from "../../assets/images/1207/1540cbd9_z.jpg";
import HotelPhoto9 from "../../assets/images/1207/ddc07f78_z.jpg";
import HotelPhoto10 from "../../assets/images/1207/e6ce3f16_z.jpg";
import HotelPhoto11 from "../../assets/images/1207/93aa66b6_z.jpg";
import HotelPhoto12 from "../../assets/images/1207/1f2e4391_z.jpg";
import HotelPhoto13 from "../../assets/images/1207/2fdf710f_z.jpg";
import HotelPhoto14 from "../../assets/images/1207/3efc481b_z.jpg";
import HotelPhoto15 from "../../assets/images/1207/4a243ba7_z.jpg";
import HotelPhoto16 from "../../assets/images/1207/6abf087d_z.jpg";
import HotelPhoto17 from "../../assets/images/1207/6c2263fe_z.jpg";
import HotelPhoto18 from "../../assets/images/1207/6dd8faa5_z.jpg";
import HotelPhoto19 from "../../assets/images/1207/531cc04b_z.jpg";

import HotelPhoto20 from "../../assets/images/1207/857a42ff_z.jpg";
import HotelPhoto21 from "../../assets/images/1207/a6dd0056_z.jpg";
import HotelPhoto22 from "../../assets/images/1207/açık büfe.jpg";
import HotelPhoto23 from "../../assets/images/1207/cf64f5b1_z.jpg";
import HotelPhoto24 from "../../assets/images/1207/e239d21d_z.jpg";
import HotelPhoto25 from "../../assets/images/1207/e732987d_z.jpg";
import HotelPhoto26 from "../../assets/images/1207/1e8b1c2a_z.jpg";

import HotelPhoto27 from "../../assets/images/1207/2bebd9f2_b.jpg";
import HotelPhoto28 from "../../assets/images/1207/2d4ba924_b.jpg";
import HotelPhoto29 from "../../assets/images/1207/2d4ba924_z.jpg";

import HotelPhoto31 from "../../assets/images/1207/99d11417_b.jpg";
import HotelPhoto32 from "../../assets/images/1207/99d11417_z.jpg";
import HotelPhoto33 from "../../assets/images/1207/531cc04b_z.jpg";
import HotelPhoto34 from "../../assets/images/1207/538e08d8_z.jpg";
import HotelPhoto35 from "../../assets/images/1207/604b5a33_b.jpg";
import HotelPhoto36 from "../../assets/images/1207/604b5a33_z.jpg";
import HotelPhoto37 from "../../assets/images/1207/1207-boutique-tek-kisilik-oda-standard-1608063.jpg";
import HotelPhoto38 from "../../assets/images/1207/76203200_z.jpg";
import HotelPhoto39 from "../../assets/images/1207/a98a01a4_z.jpg";
import HotelPhoto40 from "../../assets/images/1207/BANYO J_SUIT.jpg";
import HotelPhoto41 from "../../assets/images/1207/delux.jpg";
import HotelPhoto42 from "../../assets/images/1207/deluxqueenn.jpg";
import HotelPhoto43 from "../../assets/images/1207/e8ef37b6_z.jpg";
import HotelPhoto44 from "../../assets/images/1207/e239d21d_z.jpg";
import HotelPhoto45 from "../../assets/images/1207/economy.jpg";
import HotelPhoto46 from "../../assets/images/1207/eko.jpg";
import HotelPhoto47 from "../../assets/images/1207/family.jpg";
import HotelPhoto48 from "../../assets/images/1207/handicapped.jpg";
import HotelPhoto49 from "../../assets/images/1207/junor.jpg";
import HotelPhoto50 from "../../assets/images/1207/superiordouble.jpg";
import HotelPhoto51 from "../../assets/images/1207/triple.png";
import HotelPhoto52 from "../../assets/images/1207/Trpl.jpg";
import HotelPhoto53 from "../../assets/images/1207/twin.jpg";

const Gallery = ({ isHomePage = false }) => {
  const [index, setIndex] = useState(-1);
  const navigate = useNavigate();

  const images = [
    { src: HotelPhoto1, title: "", description: "" },
    { src: HotelPhoto2, title: "", description: "" },
    { src: HotelPhoto3, title: "", description: "" },
    { src: HotelPhoto4, title: "", description: "" },
    { src: HotelPhoto5, title: "", description: "" },
    { src: HotelPhoto6, title: "", description: "" },
    { src: HotelPhoto7, title: "", description: "" },
    { src: HotelPhoto8, title: "", description: "" },
    { src: HotelPhoto9, title: "", description: "" },
    { src: HotelPhoto10, title: "", description: "" },
    { src: HotelPhoto11, title: "", description: "" },
    { src: HotelPhoto12, title: "", description: "" },
    { src: HotelPhoto13, title: "", description: "" },
    { src: HotelPhoto14, title: "", description: "" },
    { src: HotelPhoto15, title: "", description: "" },
    { src: HotelPhoto16, title: "", description: "" },
    { src: HotelPhoto17, title: "", description: "" },
    { src: HotelPhoto18, title: "", description: "" },
    { src: HotelPhoto19, title: "", description: "" },
    { src: HotelPhoto20, title: "", description: "" },
    { src: HotelPhoto21, title: "", description: "" },
    { src: HotelPhoto22, title: "", description: "" },
    { src: HotelPhoto23, title: "", description: "" },
    { src: HotelPhoto24, title: "", description: "" },
    { src: HotelPhoto25, title: "", description: "" },
    { src: HotelPhoto26, title: "", description: "" },
    { src: HotelPhoto27, title: "", description: "" },
    { src: HotelPhoto28, title: "", description: "" },
    { src: HotelPhoto29, title: "", description: "" },
    { src: HotelPhoto31, title: "", description: "" },
    { src: HotelPhoto32, title: "", description: "" },
    { src: HotelPhoto33, title: "", description: "" },
    { src: HotelPhoto34, title: "", description: "" },
    { src: HotelPhoto35, title: "", description: "" },
    { src: HotelPhoto36, title: "", description: "" },
    { src: HotelPhoto37, title: "", description: "" },
    { src: HotelPhoto38, title: "", description: "" },
    { src: HotelPhoto39, title: "", description: "" },
    { src: HotelPhoto40, title: "", description: "" },
    { src: HotelPhoto41, title: "", description: "" },
    { src: HotelPhoto42, title: "", description: "" },
    { src: HotelPhoto43, title: "", description: "" },
    { src: HotelPhoto44, title: "", description: "" },
    { src: HotelPhoto45, title: "", description: "" },
    { src: HotelPhoto46, title: "", description: "" },
    { src: HotelPhoto47, title: "", description: "" },
    { src: HotelPhoto48, title: "", description: "" },
    { src: HotelPhoto49, title: "", description: "" },
    { src: HotelPhoto50, title: "", description: "" },
    { src: HotelPhoto51, title: "", description: "" },
    { src: HotelPhoto52, title: "", description: "" },
    { src: HotelPhoto53, title: "", description: "" },
  ];

  const displayedImages = isHomePage ? images.slice(0, 10) : images;
  debugger;
  return (
    <>
      <Container>
        <Row className="g-3">
          {displayedImages.map((image, i) => (
            <Col key={i} xs={6} md={4} lg={3}>
              <img
                src={image.src}
                alt={image.title}
                className="img-fluid rounded cursor-pointer"
                onClick={() => setIndex(i)}
                style={{ objectFit: "cover", height: "200px", width: "100%" }}
              />
            </Col>
          ))}
        </Row>

        {isHomePage && images.length > 10 && (
          <Row className="mt-4">
            <Col className="text-center">
              <a className="primaryBtn hoverbtn" onClick={() => navigate("/photogallery")}>
                Tüm Resimleri Gör
              </a>
            </Col>
          </Row>
        )}
      </Container>

      <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={displayedImages}
        plugins={[Zoom]}
        zoom={{
          maxZoomPixelRatio: 3,
        }}
      />
    </>
  );
};

export default Gallery;
