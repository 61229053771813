import React, { Suspense, lazy } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LoadingSpinner from "../../components/Common/LoadingSpinner/LoadingSpinner";
import "./Home.css";
import { popularsData } from "../../utils/data";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Lazy loaded components
const Banner = lazy(() => import("../../components/Common/Banner/Banner"));
const ReservationFilter = lazy(() => 
  import("../../components/ReservationFilter/ReservationFilter")
);
const Features = lazy(() => import("../../components/Features/Features"));
const Rooms = lazy(() => import("../../components/Common/Rooms/Rooms"));
const Gallery = lazy(() => import("../../components/Gallery/Gallery"));
const PopularCard = lazy(() => import("../../components/Cards/PopularCard"));

// Intersection Observer ile lazy loading kontrolü için custom hook
const useIntersectionObserver = (callback, options = {}) => {
  const [ref, setRef] = React.useState(null);

  React.useEffect(() => {
    if (ref) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          callback();
        }
      }, options);

      observer.observe(ref);

      return () => {
        observer.disconnect();
      };
    }
  }, [ref, callback, options]);

  return setRef;
};

const Home = () => {
  const [showFeatures, setShowFeatures] = React.useState(false);
  const [showRooms, setShowRooms] = React.useState(false);
  const [showGallery, setShowGallery] = React.useState(false);
  const [showPopular, setShowPopular] = React.useState(false);

  // Intersection Observer referansları
  const featuresRef = useIntersectionObserver(() => setShowFeatures(true));
  const roomsRef = useIntersectionObserver(() => setShowRooms(true));
  const galleryRef = useIntersectionObserver(() => setShowGallery(true));
  const popularRef = useIntersectionObserver(() => setShowPopular(true));

  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>
        <Banner />
      </Suspense>

      <div className="reservation-filter-container">
        <Suspense fallback={<LoadingSpinner />}>
          <ReservationFilter />
        </Suspense>
      </div>

      <div ref={featuresRef}>
        {showFeatures && (
          <Suspense fallback={<LoadingSpinner />}>
            <Features />
          </Suspense>
        )}
      </div>

      {/* <div ref={roomsRef}>
        {showRooms && (
          <Suspense fallback={<LoadingSpinner />}>
            <Rooms />
          </Suspense>
        )}
      </div>
*/}
      <section className="popular py-5" ref={popularRef}>
        {showPopular && (
          <Suspense fallback={<LoadingSpinner />}>
            <Container>
              <Row>
                <Col md="12">
                  <div className="main_heading">
                    <h1>Odalar</h1>
                  </div>
                </Col>
              </Row>
              <Row>
                {popularsData.map((val, inx) => (
                  <Col md={3} sm={6} xs={12} className="mb-5" key={inx}>
                    <PopularCard val={val} />
                  </Col>
                ))}
              </Row>
            </Container>
          </Suspense>
        )}
      </section>

      <section className="call_us">
        <Container>
          <Row className="align-items-center">
            <Col md="8">
              <h5 className="title">ANTALYA'NIN İNCİSİ</h5>
              <h2 className="heading">
                UNUTULMAZ BİR TATİL İÇİN 1207 HOTEL'İ KEŞFEDİN!
              </h2>
              <p className="text">
                Antalya Konaklı'nın eşsiz sahillerinde, lüks ve konforun
                buluştuğu 1207 Hotel, size unutulmaz bir tatil deneyimi sunuyor.
                Akdeniz'in masmavi sularına nazır odalarımız, dünya
                mutfaklarından lezzetler sunan restoranlarımız ve özel
                plajımızla, tatilinizi bir rüyaya dönüştürüyoruz. Yenilenmiş ve
                dinlenmiş hissetmek için doğru adrestesiniz!
              </p>
            </Col>
            <Col md="4" className="text-center mt-3 mt-md-0">
              <a
                href="tel:6398312365"
                className="secondary_btn bounce"
                rel="no"
              >
                {" "}
                İletişime Geç !
              </a>
            </Col>
          </Row>
        </Container>
        <div className="overlay"></div>
      </section>

      <section className="gallery" ref={galleryRef}>
        {showGallery && (
          <Suspense fallback={<LoadingSpinner />}>
            <Container>
              <Row>
                <Col md="12">
                  <div className="main_heading">
                    <h1>Fotoğraf Galerisi</h1>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Gallery isHomePage={true} />
                </Col>
              </Row>
            </Container>
          </Suspense>
        )}
      </section>
    </>
  );
};

export default Home;