import React, { useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { Container, Row, Col, Card, Modal } from "react-bootstrap";
import ReactPlayer from "react-player/youtube";
import { Play } from "lucide-react";
import "../About/about.css";
import img from "../../assets/images/1207/galleries/screen-shot-2023-06-05-at-230312.png"
const FeatureCard = ({ icon, title, description }) => {
  return (
    <Card className="feature-card text-dark mb-4 h-100">
      <Card.Body className="d-flex flex-column">
        <div className="text-warning mb-3 fs-2">
          <i className={icon}></i>
        </div>
        <Card.Title className="fs-4 mb-3">{title}</Card.Title>
        <Card.Text className="flex-grow-1">{description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export const About = () => {
  const [showVideo, setShowVideo] = useState(false);

  const features = [
    {
      icon: "bi bi-stars",
      title: "Yüksek Takdir",
      description:
        "Otelimiz, yüksek kaliteli ve özenli performansıyla misafirlerimizin takdirini kazanmıştır.",
    },
    {
      icon: "bi bi-hourglass",
      title: "Huzurlu Saatler",
      description:
        "Sessiz ve konforlu odalarımız, sakin atmosfer ve profesyonel hizmet anlayışımızla huzurlu bir konaklama.",
    },
    {
      icon: "bi bi-geo-alt",
      title: "İyi Lokasyon",
      description:
        "Şehrin önemli noktalarına ve turistik mekanlara yakın stratejik konumumuz.",
    },
    {
      icon: "bi bi-x-lg",
      title: "Ücretsiz İptal",
      description:
        "Belirli bir süre öncesinde yapılan iptallerde herhangi bir ücret talep etmiyoruz.",
    },
    {
      icon: "bi bi-credit-card-2-front",
      title: "Ödeme Esnekliği",
      description:
        "Çeşitli ödeme seçenekleri ve erken rezervasyon indirimleri sunuyoruz.",
    },
    {
      icon: "bi bi-percent",
      title: "Özel Teklifler",
      description:
        "Misafirlerimize özel kampanyalar ve avantajlı konaklama seçenekleri sağlıyoruz.",
    },
  ];

  return (
    <>
      <Breadcrumbs title="Hakkımızda" pagename="Hakkımızda" />
      <section className="py-5">
        <Container>
          <Row className="mb-5 align-items-center">
            <Col lg={6} className="mb-4 mb-lg-0">
              <div className="about-image-content text-dark">
                <h2
                  className="mb-4 display-5 fw-bold"
                  style={{ fontFamily: "Playfair Display, serif" }}
                >
                  Hotel 1207
                </h2>
                <p
                  className="lead"
                  style={{ fontFamily: "Roboto, sans-serif" }}
                >
                  Antalya Kaleiçi'nde, şehir merkezine sadece 200 metre
                  uzaklıkta bulunan otelimiz, tüm cazibe merkezlerine kolay
                  erişim imkanı sunmaktadır. 2015 yılında açılan otelimiz, 24
                  misafir odası ile sessiz ve konforlu bir konaklama deneyimi
                  sağlar.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="position-relative video-wrapper">
                <img
                  src={img}
                  alt="Hotel 1207"
                  className="img-fluid rounded shadow w-100"
                />
                <div className="play-button" onClick={() => setShowVideo(true)}>
                  <Play size={40} className="play-icon" />
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col md={12}>
              <h2 className="text-center mb-5 section-title">
                Temel Özellikler
              </h2>
            </Col>
            {features.map((feature, index) => (
              <Col md={6} lg={4} key={index} className="mb-4">
                <FeatureCard {...feature} />
              </Col>
            ))}
          </Row>

          <Row>
            <Col md={6} className="mb-4">
              <Card className="info-card h-100">
                <Card.Body>
                  <Card.Title className="h4 mb-4">Tesisler</Card.Title>
                  <Card.Text>
                    Hotel 1207, özel bir atmosfer sunar. 2 junior suit, 1 delüxe
                    suit, çift ve tek kişilik odalar, engelli odası ve üç
                    kişilik odalar mevcuttur. Resepsiyon, Lobi, Restoran (30
                    kişilik), Bahçe Restoranı (40 kişilik) ve küçük bir havuz
                    bulunmaktadır.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="mb-4">
              <Card className="info-card h-100">
                <Card.Body>
                  <Card.Title className="h4 mb-4">Önemli Bilgiler</Card.Title>
                  <ul className="feature-list">
                    <li>✓ Günlük oda temizliği ve havlu değişimi</li>
                    <li>✓ Açık büfe kahvaltı</li>
                    <li>✓ Ücretsiz Wi-Fi</li>
                    <li>✓ Mermerli Plajı'na yakın konum</li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal
        show={showVideo}
        onHide={() => setShowVideo(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Hotel 1207 Tanıtım</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=NQgrzf8WTdA"
            width="100%"
            height="500px"
            controls={true}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default About;
