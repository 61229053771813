import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Gallery from '../../components/Gallery/Gallery';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

const PhotoGallery = () => {
  return (
    <>
      <Breadcrumbs title="Galeri" pagename="Galeri" />
      <section className='py-5'>
        <Container>
          <Row>
            <Col>
              <h2 className="text-center mb-4">Otel Galerimiz</h2>
              <p className="text-center mb-5">Lüks odalarımız ve olanaklarımızı keşfedin</p>
              <Gallery />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PhotoGallery;