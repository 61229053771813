import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import '../Footer/Footer.css'
import logo from '../../../assets/images/1207/firefox_oCzYd9BeOz.png'
const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="footer-content">
          <Col lg={3} md={6} sm={12} className="footer-column">
            <h5>Kurumsal</h5>
            <ul>
              <li><NavLink to="/about-us">Hakkımızda</NavLink></li>
              <li><a target="_blank" href="https://files.cdn.elektraweb.com/bdcac343/192f3ef2-8b28-4060-8c14-d6d0b8f7f000.pdf">Sertifikalarımız</a></li>
              {/*   <li><NavLink to="/career">Kariyer</NavLink></li>*/}
            </ul>
          </Col>
          <Col lg={3} md={6} sm={12} className="footer-column">
            <h5>Konaklama</h5>
            <ul>
             {/*  <li><NavLink to="/activities">Aktiviteler</NavLink></li>*/}
             <li><NavLink to="/roomtype">Odalar</NavLink></li>
            </ul>
          </Col>
          <Col lg={3} md={6} sm={12} className="footer-column">
            <h5>Online Rezervasyon</h5>
            <ul>
              <li><NavLink to="/OnlineRes">Rezervasyon</NavLink></li>
             {/*   <li><NavLink to="/offers">Fırsatlar</NavLink></li>
              <li><NavLink to="/concepts">Konseptler</NavLink></li>*/}
              <li><NavLink to="/contact-us">İletişim</NavLink></li>
            </ul>
          </Col>
          <Col lg={3} md={6} sm={12} className="footer-column">
          <img
                height={80}
                width={"auto"}
                src={logo}
                alt="Hotel 1207 Logo"
              />

          </Col>
        </Row>
        <Row className="footer-bottom">
          <Col md={4} className="partner-logos">
          <div className="social-icons">
              <a href="https://www.instagram.com/hotel_1207?igsh=a2preTF1Z3c1eDdi"><FaInstagram /></a>
          
            </div>
          </Col>
        </Row>
      </Container>
      <div className="copyright">
        <Container>
          <p>Copyright © 2024 1207 Hotel Special Class Kaleiçi</p>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;